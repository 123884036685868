import React, { Fragment, useState } from 'react'
import '../styles/styles.css'
import '../styles/opportunity.css'
import quoteLeft from '../img/quote-left.svg'
import quoteRight from '../img/quote-right.svg'
import picC from '../img/picc.png'
import OpportunityImg from '../img/opportunity.jpg'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import axios from 'axios'

function Opportunity() {

    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [phoneNo, setphoneNo] = useState("")
    const [category, setcategory] = useState("")
    const [message, setmessage] = useState("")
    const [fileupload, setfileupload] = useState(null)

    const Submit = () => {
        axios.post('http://localhost:8000/hyde_international/opportunity', { Name: name, Email: email, phoneno: phoneNo, Category: category, message: message, fileupload: fileupload }).then((response) => {
            alert('Message sent.')
        })
    }

    return (
        <Fragment>
            <Helmet>
                <title>Hyde International (UK) | Opportunity</title>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="title" content="Hyde International (UK) | Opportunity" />
                <meta name="description" content="Inspire future life-changing businesses with your knowledge.
Enlighten tomorrow's world leaders with your wisdom." />
                <meta name="keywords" content="talents opportunity,tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk " />
                <meta property="og:title" content="Hyde International (UK) | Opportunity" />
                <meta property="og:description" content="Inspire future life-changing businesses with your knowledge.
Enlighten tomorrow's world leaders with your wisdom." />



            </Helmet>
            <main id="main">
                <section id="hero">
                    <div className="hero-container">
                        <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">
                            <div className="carousel-inner" role="listbox">
                                <div className="carousel-item active" style={{ height: '450px', backgroundImage: `url(${OpportunityImg})` }}>
                                    <div className="carousel-container">
                                        <div className="carousel-content container">
                                            <h2 className="new-h2 main-heading-ph" style={{ marginBottom: "-30px" }}>
                                                Become Our Partner</h2>
                                            <img className="quote-img quote-img-left" src={quoteLeft} />
                                            <p style={{
                                                fontFamily: 'Crimson Text',
                                                fontStyle: 'italic',
                                                fontSize: '25px',
                                                fontWeight: '400',
                                                marginLeft: '45px',
                                                marginTop: '0px'
                                            }}>
                                                <mark className="text-phone" style={{
                                                    color: 'white',
                                                    backgroundColor: "rgba(149, 134, 119, 50%)"
                                                }}>
                                                    Inspire future life-changing businesses with your knowledge.<br />
                                                    Enlighten tomorrow's world leaders with your wisdom.</mark>
                                            </p>
                                            <img className="quote-img quote-img-right" src={quoteRight} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="contact" className="contact section-two">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <p className="p-text text-pad" style={{
                                    textAlign: 'justify',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    marginTop: "-5%"
                                }}>
                                    <span style={{
                                        fontSize: '17px',
                                        fontWeight: '300',
                                        fontFamily: 'Libre Baskerville',
                                        color: 'rgb(68,68,68)',
                                        lineHeight: "2"
                                    }}> Hyde is proud to introduce its first-className platform for pioneering businesses, experienced academics, and professionals seeking to fulfil their self-accomplishment by presenting and sharing their valuable knowledge and experiences.
                                        <br /><br />
                                        In becoming our partner, you will not only be become a business architect who resolves challenging problems with/for enthusiastic industrial leaders and policy makers, thereby bringing valuable changes to society and the world. You will also become an important companion to other brilliant start-ups and SMEs in their journey.</span></p>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="text-center">
                            <img className="img-fluid" src={picC} style={{ height: '318px', width: '499px' }} />
                        </div>
                    </div>
                </section>

                {/* <!-- ======= Contact Us Section ======= --> */}
                <section id="contact" className="contact" style={{ paddingBottom: "75px" }}>
                    <div className="container-fluid">
                        {/* <!-- <img className="mx-auto d-block" src="assets/img/joinus.png" style="margin-bottom: 30px;"> --> */}

                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-6 ph-width" style={{ backgroundColor: 'white', boxShadow: '0 0 30px rgba(110, 110, 110, 0.6)', padding: "40px" }}>
                                <div>
                                    <h2 className="text-center main-heading form-title-ph" style={{ marginBottom: '20px', color: '#958677', fontSize: "3vw" }}>Join Us</h2>
                                </div>
                                <form enctype="multipart/form-data"
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontSize: '17px',
                                        fontWeight: "300"
                                    }}
                                >
                                    <div className="form-row" style={{ marginTop: "40px" }}>
                                        <div className="col-lg-6 form-group">
                                            <input type="text" name="name" className="form-control text-phone" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" onChange={(e) => setname(e.target.value)} required />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <input type="email" className="form-control text-phone" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" onChange={(e) => setemail(e.target.value)} required />
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control text-phone" name="phone" id="subject" placeholder="Phone Number" minlength="8" maxlength="12" data-msg="Please enter your phone number" onChange={(e) => setphoneNo(e.target.value)} required />
                                    </div>
                                    <div className="form-group text-phone">
                                        <select name="cata" className="browser-default custom-select" onChange={(e) => setcategory(e.target.value)}>
                                            <option selected>Culture, Arts and Social Scineces</option>
                                            <option value="Medicine and Health">Medicine and Health</option>
                                            <option value="Leadership, Managment, Buisness and Commerce">Leadership, Managment, Buisness and Commerce</option>
                                            <option value="Sceince, Agriculture and Engineering">Sceince, Agriculture and Engineering</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control-a text-phone" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="  Message" onChange={(e) => setmessage(e.target.value)}></textarea>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="mb-3">
                                        {/* <!-- <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div> --> */}
                                    </div>
                                    <strong>Upload File (CV/Resume*):
                                        <input type="file" name="atta_file" multiple onChange={(e) => setfileupload(e.target.files)} />
                                    </strong>
                                    <input name="submit" type="button" style={{
                                        borderRadius: "5px",
                                        width: '110px',
                                        margin: '14px',
                                        height: '50px',
                                        lineHeight: "0"
                                    }}
                                        className="btn btn-primary pull-right submit-btn-phone text-phone"
                                        value="Submit" onClick={Submit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End Contact Us Section --> */}
            </main>
            {/* <!-- End #main --> */}
        </Fragment>
    )
}

export default Opportunity
