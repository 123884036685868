import axios from 'axios'
import React, { useEffect, useState } from 'react'
import '../styles/admin.css'
import { Col, Modal, Row } from 'react-bootstrap';

function Admin() {

    const [edit, setedit] = useState(false)
    const [aDDress, setaDDress] = useState([])
    const [officeName, setofficeName] = useState('')
    const [officePhone, setofficePhone] = useState('')
    const [officeEmail, setofficeEmail] = useState('')
    const [officeAddress, setofficeAddress] = useState('')
    const [hydeData, sethydeData] = useState([])

    const [lgShow, setLgShow] = useState(false);

    useEffect(() => {
        axios.get('http://localhost:8000/hyde_international/address').then((response) => {
            if (response) {
                setaDDress(response.data.data)
            }
        })
    }, [])


    const saveHandler = (officeID) => {
        setedit(!edit)
        axios.put('http://localhost:8000/hyde_international/edit_address', { officeName: officeName, officePhone: officePhone, officeEmail: officeEmail, officeAddress: officeAddress, id_address: officeID }).then((response) => {
            if (response) {
                alert('Address Updated.')
                window.location.reload(false)
            }
        })
    }
    return (
        <div style={{ height: '75vh' }}>
            <div className='container'>
                {aDDress.map((val) => {
                    return <>
                        <div className='left-address'><div>
                            <label className='label-control' htmlFor="officeName">Office Name :</label>
                            <label className='label-control'>{val.OfficeName}</label>
                            <label className='label-control' htmlFor="officePhone">Office Phone :</label>
                            <label className='label-control'>{val.OfficePhone}</label>
                            <label className='label-control' htmlFor="officePhone">Office Email :</label>
                            <label className='label-control'>{val.OfficeEmail}</label>
                            <label className='label-control' htmlFor="officePhone">Office Address :</label>
                            <p style={{ height: '50px' }}>{val.OfficeAddress}</p>
                            <br />
                            <button className='applyBtn' onClick={() => {
                                setedit(!edit)
                                setLgShow(!lgShow)
                                sethydeData(val)
                                setofficeName(val.OfficeName)
                                setofficePhone(val.OfficePhone)
                                setofficeEmail(val.OfficeEmail)
                                setofficeAddress(val.OfficeAddress)
                            }}>Edit</button></div></div>

                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    Company Information
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <label>Address ID :</label>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <p style={{ fontSize: '14px' }}>{val.id_address}</p>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <label>Office Name :</label>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        {edit ? <input type='text' className='form-control' value={officeName} onChange={(e) => { setofficeName(e.target.value) }} />
                                            : <p style={{ fontSize: '14px' }}>{officeName}</p>}
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <label>Office Phone :</label>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        {edit ? <input type='text' className='form-control' value={officePhone} onChange={(e) => { setofficePhone(e.target.value) }} />
                                            : <p style={{ fontSize: '14px' }}>{officePhone}</p>}
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <label>Office Email :</label>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        {edit ? <input type='text' className='form-control' value={officeEmail} onChange={(e) => { setofficeEmail(e.target.value) }} />
                                            : <p style={{ fontSize: '14px' }}>{officeEmail}</p>}
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <label>Office Address :</label>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        {edit ? <input type='text' className='form-control' value={officeAddress} onChange={(e) => { setofficeAddress(e.target.value) }} />
                                            : <p style={{ fontSize: '14px' }}>{officeAddress}</p>}
                                    </Col>
                                </Row>
                                <br />
                            </Modal.Body>
                            <Modal.Footer >
                                <button type="button" className='applyBtn' onClick={() => saveHandler(hydeData.id_address)}>Save</button>
                            </Modal.Footer>
                        </Modal>
                    </>


                })}

            </div>
        </div >
    )
}

export default Admin
