import React, { Fragment, useEffect, useState } from 'react'
import '../styles/styles.css'
import '../styles/contactUs.css'
import contactImg from '../img/contact.jpg'
import ukImg from '../img/uk.png'
import chinaImg from '../img/china.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'


function ContactUs() {
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [phone, setphone] = useState("")
    const [subject, setsubject] = useState("")
    const [message, setmessage] = useState("")

    const [address, setaddress] = useState([])

    // tesgin ='http://www.hydeinternational.co.uk/contactus'

    useEffect(() => {
        axios.get('http://localhost:8000/hyde_international/address').then((response) => {
            if (response) {
                setaddress(response.data.data)
            }
        })
    }, [])

    const submitHandler = () => {
        axios.post('http://localhost:8000/hyde_international/contact-us', { Name: name, Email: email, Phone: phone, Subject: subject, Message: message }).then((response) => {
            alert('Email Sent.')
        })
    }

    return (
        <Fragment>
            <Helmet>
                <title>Hyde International (UK) | Contact Us</title>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="title" content="Hyde International (UK) | Contact Us" />
                <meta name="description" content="London Office
 +44 (0) 207 712 1505
 uk@hyde-china.com
Hyde International (UK), 37th Floor, One Canada
Square, Canary Wharf, London, United Kingdom,
E14 5AA" />
                <meta name="keywords" content="funding program,tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk " />
                <meta property="og:title" content="Hyde International (UK) | Contact Us" />
                <meta property="og:description" content="London Office
 +44 (0) 207 712 1505
 uk@hyde-china.com
Hyde International (UK), 37th Floor, One Canada
Square, Canary Wharf, London, United Kingdom,
E14 5AA" />



            </Helmet>
            {/* <!-- ======= Contact Section ======= --> */}
            <section id="contact" className="contact">
                <div className="container-fluid">
                    <div className="contact_container">
                        <div className="col-lg-6 col-xl-6" style={{ paddingRight: '0px', paddingLeft: "0px" }}>
                            <form role="form" className="form-height text-ph" style={{
                                backgroundColor: 'rgb(227, 222, 218)',
                                padding: '26px',
                                fontFamily: 'Mulish',
                                fontSize: '17px',
                                fontWeight: '300',
                                textAlign: '-webkit-center'
                            }}>
                                <div className="section-title heading-underline">
                                    <h2 className="sub-hd-ph" style={{
                                        fontSize: '33px',
                                        fontFamily: 'Tenor Sans',
                                        fontWeight: '400',
                                        color: 'rgb(68, 68, 68)'
                                    }}>Get In Touch</h2>
                                </div>
                                <div className="form-row" style={{ marginTop: "3%" }}>
                                    <div className="col-lg-6 form-group">
                                        <input type="text" name="Name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required="" onChange={(e) => setname(e.target.value)} />
                                        <div className="validate"></div>
                                    </div>
                                    <div class="col-lg-6 form-group">
                                        <input type="email" className="form-control" name="Email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required="" onChange={(e) => setemail(e.target.value)} />
                                        <div class="validate"></div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="tel" className="form-control" name="Phone" id="phone" placeholder="Phone" minlength="8" maxlength="12" data-msg="Please enter your phone number" required="" onChange={(e) => setphone(e.target.value)} />
                                    <div class="validate"></div>
                                </div>
                                <div class="form-group">
                                    <input type="text" className="form-control" name="Subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" required="" onChange={(e) => setsubject(e.target.value)} />
                                    <div class="validate"></div>
                                </div>
                                <div class="form-group">
                                    <textarea className="form-control" name="Message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message" required="" onChange={(e) => setmessage(e.target.value)}></textarea>
                                    <div className="validate"></div>
                                </div>
                                <div className="mb-3">
                                    {/* <!-- <input type="" name="validet" value="true" style="display: none;"> --> */}
                                </div>
                                <div className="mr-tb text-center" style={{
                                    marginTop: '5%',
                                    top: '-20px',
                                    position: "relative"
                                }}>
                                    <input name="send" type="button" style={{
                                        borderRadius: '5px',
                                        width: '110px',
                                        margin: '14px',
                                        height: '50px',
                                        lineHeight: '0',
                                        background: 'rgb(22, 89, 148)',
                                        border: '0',
                                        padding: '10px 24px',
                                        color: '#fff',
                                        transition: '0.4s'
                                    }} className="btn btn-primary" value="Send" onClick={submitHandler} />


                                    <p></p>


                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 col-xl-6" style={{
                            paddingLeft: '0px',
                            paddingRight: "0px"
                        }}>
                            <img src={contactImg} className="form-height no-img-ph" />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Address & Maps Start --> */}


            {/* <!-- <div class="section-title">
          <h2 style="font-size: 3vw;" class="main-heading text-phon">Our Locations</h2>
        </div> --> */}
            {address.map((val) => {
                return <>
                    <section id="contact" className="contact" style={{ padding: "40px 0" }}>
                        <div className="container">
                            <div className="row row-paddin rm-bg-ph" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6)" }}>
                                <div className="col-md-6 col-lg-6" style={{ paddingRight: "0px" }}>
                                    <div className="php-email-form text-center main-heading">
                                        <h2 className="address-ph-a sub-hd-ph" style={{
                                            textAlign: 'left',
                                            marginTop: '15%',
                                            marginLeft: '15%',
                                            color: 'rgb(68, 68, 68)',
                                            fontSize: '25px',
                                            fontFamily: 'Tenor Sans',
                                            fontWeight: "400"
                                        }}>{val.OfficeName}</h2>
                                        <p className="address-ph text-ph" style={{
                                            lineHeight: '1.5',
                                            textAlign: 'justify',
                                            marginLeft: '15%',
                                            color: 'rgb(68, 68, 68)',
                                            fontSize: '17px',
                                            fontFamily: 'Mulish',
                                            fontWeight: "300"
                                        }}>
                                            <i className="fa fa-phone"> {val.OfficePhone}</i><br />
                                            <i className="fa fa-envelope"> {val.OfficeEmail}</i><br />
                                            {val.OfficeAddress}
                                        </p>
                                    </div>
                                </div>
                                {val.id_address === 1 ? <div className="col-md-6 col-lg-6" style={{ paddingLeft: "0px" }}>
                                    <img className="rm-map-ph" src={ukImg} style={{ width: '100%', height: 'auto', padding: "30px" }} />
                                </div> : <div className="col-md-6 col-lg-6" style={{ paddingLeft: "0px" }}>
                                    <img className="rm-map-ph" src={chinaImg} style={{ width: '100%', height: 'auto', padding: "30px" }} />
                                </div>}
                            </div>
                        </div>
                    </section>


                </>
            })}
            {/* 
            <section id="contact" className="contact" style={{ padding: "40px 0px 75px" }}>
                <div className="container">
                    <div className="row row-paddin rm-bg-ph" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6)" }}>
                        <div className="col-md-6 col-lg-6" style={{ paddingRight: "0px" }}>
                            <div className="php-email-form text-center main-heading">
                                <h2 className="address-ph-a sub-hd-ph" style={{
                                    textAlign: 'left',
                                    marginTop: '15%',
                                    marginLeft: '15%',
                                    color: 'rgb(68, 68, 68)',
                                    fontSize: '25px',
                                    fontFamily: 'Tenor Sans',
                                    fontWeight: "400"
                                }}>Beijing Office</h2>
                                <p className="address-ph text-ph" style={{
                                    lineHeight: '1.5',
                                    textAlign: 'justify',
                                    marginLeft: '15%',
                                    color: 'rgb(68, 68, 68)',
                                    fontSize: '17px',
                                    fontFamily: 'Mulish',
                                    fontWeight: "300"
                                }}>
                                    <i className="fa fa-phone"> +86 (0)10 571 758 00</i><br />
                                    <i className="fa fa-envelope"> hyde@hyde-china.com</i><br />
                                    北京市海淀区车公庄西路乙19号华通大厦B座北塔926-928
                                    926-928, North Tower, Building B, Hua Tong Building, Yi 19, Chegongzhuang West Road, Haidian District, Beijing, China, 100044<br />
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6" style={{ paddingLeft: "0px" }}>
                            <div className="php-email-form">
                                <img className="rm-map-ph" src={chinaImg} style={{ width: '100%', height: 'auto', padding: "30px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- Address & Maps End --> */}
            <main id="main">

            </main>
            {/* <!-- End #main --> */}

        </Fragment>
    )
}

export default ContactUs
