import React, { Fragment, useState } from 'react'
import promotionVideo from '../img/slide/Hyde-Promotion.mp4.filepart'
import opp_Sol from '../img/opp&sol.png'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

function Aboutus() {

    const [extendvalue, setextendvalue] = useState(false)
    const [extendaim, setextendaim] = useState(false)
    const [extendmission, setextendmission] = useState(false)
    const [extendstd, setextendstd] = useState(false)
    const [extendcust, setextendcust] = useState(false)
    const [extendink, setextendink] = useState(false)

    const extendToggleValue = () => {
        setextendvalue(!extendvalue)
    }
    const extendToggleAim = () => {
        setextendaim(!extendaim)
    }
    const extendToggleMission = () => {
        setextendmission(!extendmission)
    }
    const extendToggleStQ = () => {
        setextendstd(!extendstd)
    }
    const extendToggleCust = () => {
        setextendcust(!extendcust)
    }
    const extendToggleInK = () => {
        setextendink(!extendink)
    }
    return (
        <Fragment>
              <Helmet>
                    <title>Hyde International (UK) | About</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="HYDE INTERNATIONAL (UK) | About" />
                    <meta name="description" content="Hyde International (UK) is a bespoke international cross-cultural solution and opportunity provider for organisations and talents between Europe and China who are dedicated to creating changes that matter." />
                    <meta name="keywords" content="research and developement grant,tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk, hyde about us " />
                    <meta property="og:title" content="HYDE INTERNATIONAL (UK) | About" />
                    <meta property="og:description" content="Hyde International (UK) is a bespoke international cross-cultural solution and opportunity provider for organisations and talents between Europe and China who are dedicated to creating changes that matter." />



                </Helmet>
            <section id="hero" classNameName="id-herro abt-hgt">
                <div classNameName="hero-container">
                    <div id="heroCarousel" classNameName="carousel slide">
                        <div classNameName="carousel-inner" role="listbox">
                            <div classNameName="carousel-item active vid-height" style={{ height: "545px" }}>
                                <div classNameName="carousel-container">
                                    <video classNameName="video-fluid z-depth-1" autoplay loop muted src={promotionVideo}>

                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main id="main">
                {/* <!-- ======= About Us Section ======= --> */}
                <section style={{ paddingBottom: "75px" }} id="about" className="section-mr-top">
                    <div className="container">
                        <div className="box-design">
                            <div className="row">
                                <div className="col-lg-2"> </div>
                                <div className="col-lg-8
          " style={{ textAlign: "-webkit-center" }}>
                                    <h2 className="heading-underline sub-h-ph about-us-margin-top-ph" style={{
                                        marginBottom: '10px',
                                        color: '#18407c',
                                        fontFamily: 'Libre Baskerville',
                                        fontWeight: '400',
                                        color: 'rgb(68, 68, 68)'
                                    }}>
                                        About Us</h2>
                                    <p className="text-ph" style={{
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                        fontWeight: '300',
                                        color: 'rgb(68, 68, 68)',
                                        fontFamily: 'Libre Baskerville',
                                        lineHeight: '1.5'
                                    }}><span style={{ color: "#18407c" }}>Hyde International (UK)</span> is a bespoke international cross-cultural solution and opportunity provider for organisations and talents between Europe and China who are dedicated to creating changes that matter.</p></div>
                                <div className="col-lg-2"> </div>
                            </div>
                        </div>

                        {/* <!-- End About Us Section --> */}


                        <div className="row">
                            <div className="col-lg-2"> </div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" id="value" style={{
                                    borderBottom: "1px solid black",
                                    borderTop: '1px solid black'
                                }}>
                                    <div className="on-hov" style={{ marginBottom: "-16px" }}>
                                        <a type="button" className="click sub-h-ph mr-ph-0 main-heading btn btn-infoo" data-toggle="collapse" data-target="#data_1" style={{
                                            textAlign: "left",
                                            fontSize: '24px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: '-20px',
                                        }} onClick={extendToggleValue}>Value

                                        </a><ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleValue} />
                                        <ul className="pagination" style={{
                                            marginTop: '1rem',
                                            marginLeft: "18px",
                                            marginBottom: '26px',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: 'rgb(149, 134, 119)'
                                        }}>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: '10px'
                                            }}>
                                                Integrity & Partnership</a></li>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: "10px"
                                            }}>
                                                Agility & Diversity&nbsp;&nbsp;&nbsp;</a></li>
                                            <li><a>Possibility & Responsibility</a></li>
                                        </ul>
                                    </div>

                                    {extendvalue ? <div id="data_1" className="collapse show" style={{ transition: 'all 0.6s;' }}>
                                        <table className="table" style={{
                                            background: 'rgba(149, 134, 119, 40%)',
                                            marginBottom: 'unset',
                                            marginTop: '2px',
                                            fontFamily: 'Libre Baskerville'
                                        }}>
                                            <tr>
                                                <td className="tbl-col-2">Integrity & Partnership</td>
                                                <td className="tbl-col-1">We work as one global team, both with each other and our clients, to direct our
                                                    collective energy towards achieving the extraordinary.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tbl-col-2">Agility & Diversity</td>
                                                <td className="tbl-col-1">We aim to adapt and respond to change with flexibility and intellectual acuity and respect for all individuals.</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td className="tbl-col-2">Possibility & Responsibility</td>
                                                <td className="tbl-col-1">We embrace innovation and remain open to new possibilities. We measure our success by our client's sustainable success, and we believe we can make a better world.
                                                </td>
                                            </tr>
                                        </table>
                                    </div> : <div id="data_1" className="collapse" style={{ transition: 'all 0.6s;' }}>
                                        <table className="table" style={{
                                            background: 'rgba(149, 134, 119, 40%)',
                                            marginBottom: 'unset',
                                            marginTop: '2px',
                                            fontFamily: 'Libre Baskerville'
                                        }}>
                                            <tr>
                                                <td className="tbl-col-2">Integrity & Partnership</td>
                                                <td className="tbl-col-1">We work as one global team, both with each other and our clients, to direct our
                                                    collective energy towards achieving the extraordinary.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tbl-col-2">Agility & Diversity</td>
                                                <td className="tbl-col-1">We aim to adapt and respond to change with flexibility and intellectual acuity and respect for all individuals.</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td className="tbl-col-2">Possibility & Responsibility</td>
                                                <td className="tbl-col-1">We embrace innovation and remain open to new possibilities. We measure our success by our client's sustainable success, and we believe we can make a better world.
                                                </td>
                                            </tr>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"> </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" id="aim" style={{ borderBottom: "1px solid black" }}>
                                    <div className="on-hov" style={{ marginBottom: "-16px" }}>
                                        <a type="button" className="click sub-h-ph mr-ph-0 main-heading btn btn-infoo" data-toggle="collapse" data-target="#data_2" style={{
                                            textAlign: 'left',
                                            fontSize: '22px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "-20px"
                                        }} onClick={extendToggleAim}>Aim
                                        </a> <ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleAim} />
                                        <ul className="pagination font-ph" style={{
                                            marginTop: '1rem',
                                            marginLeft: '18px',
                                            marginBottom: '26px',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: 'rgb(149, 134, 119)'
                                        }}>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: "10px"
                                            }}>
                                                To Stay Informed/Knowledgeable&nbsp;&nbsp;&nbsp;</a></li>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: "10px"
                                            }}>
                                                To Stay Ready&nbsp;&nbsp;&nbsp;</a></li>
                                            <li ><a>To Stay Connected</a></li>
                                        </ul>
                                    </div>
                                    {extendaim ? <div id="data_2" className="collapse show" style={{transition: 'all 0.6s;' }}>
                                        <table className="table" style={{
                                            background: 'rgba(149, 134, 119, 40%)',
                                            marginBottom: 'unset',
                                            marginTop: '2px',
                                            fontFamily: 'Libre Baskerville'
                                        }}>
                                            <tr>
                                                <td className="tbl-col-2">To Stay Informed/<br />Knowledgeable</td>
                                                <td className="tbl-col-1">We believe in the free flow of knowledge sharing. We strive to break down boundaries to knowledge exchange. No matter where you are or which cultural background you are from, we aim to get you access to the best resources of knowledge in the world.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tbl-col-2">To Stay<br />Ready</td>
                                                <td className="tbl-col-1">We hatch brilliant ideas aimed at changing individual communities and the world. Whether you are an experienced business in the industry with an innovative concept or a first-year university student with an inspiring idea, we help to get your ideas off the ground.</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td className="tbl-col-2">To Stay Connected</td>
                                                <td className="tbl-col-1">We believe in the power of connections and are dedicated to expanding and maintaining our networking and social circles. We fight for what our clients are fighting for.
                                                </td>
                                            </tr>
                                        </table>
                                    </div> :
                                        <div id="data_2" className="collapse" style={{ transition: 'all 0.6s;' }}>
                                            <table className="table" style={{
                                                background: 'rgba(149, 134, 119, 40%)',
                                                marginBottom: 'unset',
                                                marginTop: '2px',
                                                fontFamily: 'Libre Baskerville'
                                            }}>
                                                <tr>
                                                    <td className="tbl-col-2">To Stay Informed/<br />Knowledgeable</td>
                                                    <td className="tbl-col-1">We believe in the free flow of knowledge sharing. We strive to break down boundaries to knowledge exchange. No matter where you are or which cultural background you are from, we aim to get you access to the best resources of knowledge in the world.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="tbl-col-2">To Stay<br />Ready</td>
                                                    <td className="tbl-col-1">We hatch brilliant ideas aimed at changing individual communities and the world. Whether you are an experienced business in the industry with an innovative concept or a first-year university student with an inspiring idea, we help to get your ideas off the ground.</td>
                                                </tr>
                                                <br />
                                                <tr>
                                                    <td className="tbl-col-2">To Stay Connected</td>
                                                    <td className="tbl-col-1">We believe in the power of connections and are dedicated to expanding and maintaining our networking and social circles. We fight for what our clients are fighting for.
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>


                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" id="mission" style={{ borderBottom: '1px solid black' }}>
                                    <div className="on-hov" style={{ marginBottom: "-16px" }}>
                                        <a type="button" className="click main-heading mr-ph-0 sub-h-ph btn btn-infoo" data-toggle="collapse" data-target="#data_3" style={{
                                            textAlign: 'left',
                                            fontSize: '24px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "-20px"
                                        }} onClick={extendToggleMission}>Mission
                                        </a> <ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleMission} />
                                        <ul className="pagination font-ph" style={{
                                            marginTop: '1rem',
                                            marginLeft: '18px',
                                            marginBottom: '26px',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: "rgb(149, 134, 119)"
                                        }}>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: "10px"
                                            }}>
                                                Business Architect&nbsp;&nbsp;&nbsp;</a></li>
                                            <li><a style={{
                                                borderRight: '1px solid black',
                                                marginRight: "10px"
                                            }}>
                                                Resource Re-allocator&nbsp;&nbsp;&nbsp;</a></li>
                                            <li><a>Cultural Barrier Breaker</a></li>
                                        </ul>
                                    </div>
                                    {extendmission ? <div id="data_3" className="collapse show" style={{ transition: 'all 0.6s;' }}>
                                        <table className="table" style={{
                                            background: 'rgba(149, 134, 119, 40%)',
                                            marginBottom: 'unset',
                                            marginTop: '2px',
                                            fontFamily: 'Libre Baskerville'
                                        }}>
                                            <tr>
                                                <td className="tbl-col-2">Business Architect</td>
                                                <td className="tbl-col-1">We create values and customise our client’s business empire specifically for them. Our experienced and sophisticated experts always guard our clients and their business ideas' success.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tbl-col-2">Resource Re-allocator</td>
                                                <td className="tbl-col-1">The concept of Waste and Resource is relative. Hyde identifies the most suitable resources for our clients and makes the best of those resources available to them.</td>
                                            </tr>
                                            <tr>
                                                <td className="tbl-col-2">Cultural Barrier Breaker</td>
                                                <td className="tbl-col-1">We are internationalists with expertise not only in our clients’ languages, but also in what our clients do right.
                                                </td>
                                            </tr>
                                        </table>
                                    </div> :
                                        <div id="data_3" className="collapse" style={{ transition: 'all 0.6s;' }}>
                                            <table className="table" style={{
                                                background: 'rgba(149, 134, 119, 40%)',
                                                marginBottom: 'unset',
                                                marginTop: '2px',
                                                fontFamily: 'Libre Baskerville'
                                            }}>
                                                <tr>
                                                    <td className="tbl-col-2">Business Architect</td>
                                                    <td className="tbl-col-1">We create values and customise our client’s business empire specifically for them. Our experienced and sophisticated experts always guard our clients and their business ideas' success.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="tbl-col-2">Resource Re-allocator</td>
                                                    <td className="tbl-col-1">The concept of Waste and Resource is relative. Hyde identifies the most suitable resources for our clients and makes the best of those resources available to them.</td>
                                                </tr>
                                                <tr>
                                                    <td className="tbl-col-2">Cultural Barrier Breaker</td>
                                                    <td className="tbl-col-1">We are internationalists with expertise not only in our clients’ languages, but also in what our clients do right.
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>


                        <div className="row" id="whyus">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8" style={{ textAlign: "-webkit-center" }}>
                                <div className="bg-grey box-design" style={{ borderBottom: "1px solid black" }}>
                                    <h2 className="text-left sub-h-ph whyus-margin-top-ph heading-underline" style={{
                                        marginBottom: '10px',
                                        fontFamily: 'Libre Baskerville',
                                        fontWeight: '400',
                                        color: 'rgb(68, 68, 68)',
                                        marginTop: "75px"
                                    }}>Why Us</h2>
                                    {/* <!-- <img className="mx-auto d-block" src="assets/img/whyus.png" width="20%" height="20%"> --> */}
                                    <div className="text-ph" style={{
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                        fontWeight: '300',
                                        color: 'rgb(68, 68, 68)',
                                        fontFamily: 'Libre Baskerville',
                                        lineHeight: "1.5"
                                    }}>
                                        <p><span style={{ color: "#18407c" }}>Hyde International (UK)</span> has developed and maintained excellent stable and long-term relations over 20 years with universities, enterprises, government ministries, departments, and organisations in China and the rest of the world (e.g. the UK, Italy, Spain).
                                        </p>
                                        <p>
                                            We are dedicated to continuous improvement in our service quality so that our clients can maximise their value through learning and development while gaining better
                                        </p>
                                        <div className="text-center">
                                            <img className="img-fluid" src={opp_Sol} style={{
                                                marginTop: '17px',
                                                marginBottom: "17px"
                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>


                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" style={{ borderBottom: "1px solid black" }}>
                                    <div className="on-hov adj">
                                        <a type="button" className="sub-h-ph mr-ph-0 click btn btn-infoo" data-toggle="collapse" data-target="#data_4" style={{
                                            textAlign: 'left',
                                            fontSize: '24px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "-20px"
                                        }} onClick={extendToggleStQ}>Standards & Quality
                                        </a> <ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleStQ} />
                                        <p className="font-ph" style={{
                                            marginTop: '1rem',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: 'rgb(149, 134, 119)',
                                            marginLeft: "16px"
                                        }}>Unlocking potential to advance the world</p>
                                    </div>

                                    {extendstd ? <div id="data_4" className="collapse show" style={{
                                        transition: 'all 0.6s;',
                                        background: 'rgba(149, 134, 119, 40%)',
                                        paddingLeft: "15px"
                                    }}>
                                        <p className="tbl-col-3" style={{
                                            marginBottom: 'unset',
                                            marginTop: '-40px !important',
                                            fontFamily: 'Libre Baskerville',
                                            fontSize: "17px"
                                        }}>
                                            <br />
                                            We believe in the power of human potential to shape strategic, organisational, economic, societal, and other changes. We aspire to grow by helping others grow, all while maintaining the highest professional and ethical standards.</p>
                                    </div> :
                                        <div id="data_4" className="collapse" style={{
                                            background: 'rgba(149, 134, 119, 40%)',
                                            paddingLeft: "15px",
                                            transition: 'all 0.6s;'
                                        }}>
                                            <p className="tbl-col-3" style={{
                                                marginBottom: 'unset',
                                                marginTop: '-40px !important',
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: "17px"
                                            }}>
                                                <br />
                                                We believe in the power of human potential to shape strategic, organisational, economic, societal, and other changes. We aspire to grow by helping others grow, all while maintaining the highest professional and ethical standards.</p>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" style={{ borderBottom: "1px solid black" }}>
                                    <div className="on-hov adj">
                                        <a type="button" className="sub-h-ph mr-ph-0 click btn btn-infoo" data-toggle="collapse" data-target="#data_5" style={{
                                            textAlign: 'left',
                                            fontSize: '24px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "-20px"
                                        }} onClick={extendToggleCust}>Customisation

                                        </a><ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleCust} />
                                        <p className="font-ph" style={{
                                            marginTop: '1rem',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: 'rgb(149, 134, 119)',
                                            marginLeft: "16px"
                                        }}>Making changes that matter</p>
                                    </div>

                                    {extendcust ? <div id="data_5" className="collapse show" style={{ background: 'rgba(149, 134, 119, 40%)', paddingLeft: "15px",transition: 'all 0.6s;' }}>
                                        <p className="tbl-col-3" style={{
                                            marginBottom: 'unset',
                                            marginTop: '-40px !important',
                                            fontFamily: 'Libre Baskerville',
                                            fontSize: "17px"
                                        }}>
                                            <br />
                                            We provide truly bespoke services that can transform our clients in the ways that most matter to them and hence maximise their value.</p>
                                    </div> : <div id="data_5" className="collapse" style={{ background: 'rgba(149, 134, 119, 40%)', paddingLeft: "15px",transition: 'all 0.6s;' }}>
                                        <p className="tbl-col-3" style={{
                                            marginBottom: 'unset',
                                            marginTop: '-40px !important',
                                            fontFamily: 'Libre Baskerville',
                                            fontSize: "17px"
                                        }}>
                                            <br />
                                            We provide truly bespoke services that can transform our clients in the ways that most matter to them and hence maximise their value.</p>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <div className="kp bg-grey box-design" style={{ borderBottom: "1px solid black" }}>
                                    <div className="on-hov adj">
                                        <a type="button" className="sub-h-ph mr-ph-0 click btn btn-infoo" data-toggle="collapse" data-target="#data_6" style={{
                                            textAlign: 'left',
                                            fontSize: '24px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: '400',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "-20px"
                                        }} onClick={extendToggleInK}>Innovation & Knowledge

                                        </a><ExpandMoreIcon style={{ width: '8%', height: '8%', float: 'right' }} onClick={extendToggleInK} />
                                        <p className="font-ph" style={{
                                            marginTop: '1rem',
                                            fontSize: '17px',
                                            fontWeight: '400',
                                            fontFamily: 'Libre Baskerville',
                                            color: 'rgb(149, 134, 119)',
                                            marginLeft: "16px"
                                        }}>Ensuring that our clients define us</p>
                                    </div>

                                    {extendink ? <div id="data_6" className="collapse show" style={{ background: 'rgba(149, 134, 119, 40%)', paddingLeft: "15px" ,transition: 'all 0.6s;'}}>
                                        <p className="tbl-col-3" style={{
                                            marginBottom: 'unset',
                                            marginTop: '-40px !important',
                                            fontFamily: 'Libre Baskerville',
                                            fontSize: "17px"
                                        }}>
                                            <br />
                                            Our Think Tank is powered by knowledge and innovation. Our work is founded on a rigorous understanding of every client’s institutional context, sector dynamics, and macroeconomic situation.</p>
                                    </div> : <div id="data_6" className="collapse" style={{ background: 'rgba(149, 134, 119, 40%)', paddingLeft: "15px",transition: 'all 0.6s;' }}>
                                        <p className="tbl-col-3" style={{
                                            marginBottom: 'unset',
                                            marginTop: '-40px !important',
                                            fontFamily: 'Libre Baskerville',
                                            fontSize: "17px"
                                        }}>
                                            <br />
                                            Our Think Tank is powered by knowledge and innovation. Our work is founded on a rigorous understanding of every client’s institutional context, sector dynamics, and macroeconomic situation.</p>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>


                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default Aboutus
