import React, { Fragment } from 'react'
import '../styles/services.css'
import img13 from '../img/Picture13.png'
import img16 from '../img/Picture16.png'
import img15 from '../img/Picture15.png'
import img14 from '../img/Picture14.png'
import img1 from '../img/Picture1.svg'
import img2 from '../img/Picture2.svg'
import img3 from '../img/Picture3.svg'
import img4 from '../img/Picture4.svg'
import img5 from '../img/Picture5.svg'
import img6 from '../img/Picture6.svg'
import img7 from '../img/Picture7.svg'
import img8 from '../img/Picture8.svg'
import img9 from '../img/Picture9.svg'
import img10 from '../img/Picture10.svg'
import img11 from '../img/Picture11.svg'
import img12 from '../img/Picture12.svg'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'



function Services() {
    return (
        <Fragment>
               <Helmet>
                    <title>Hyde International (UK) | Services</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="Hyde International (UK) | Services" />
                    <meta name="description" content="Hyde has been dedicated to providing supreme services to improve our clients’ competitiveness and industry influences. We strive to reinforce the critical skills of our clients’ team members and to make sustainable and impactful changes in society." />
                    <meta name="keywords" content="hyde services, client support,hyde international uk,hyde uk" />
                    <meta property="og:title" content="Hyde International (UK) | Services" />
                    <meta property="og:description" content="Hyde has been dedicated to providing supreme services to improve our clients’ competitiveness and industry influences. We strive to reinforce the critical skills of our clients’ team members and to make sustainable and impactful changes in society." />



                </Helmet>
            <main id="main">
                {/* <!-- ======= Services Section ======= --> */}
                <section id="services" className="services" style={{ paddingTop: "75px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-9">
                                <div className="section-title text-left section-title-phone" style={{ marginLeft: "90px" }}>
                                    <h2 className="heading-underline service-top-mt sub-h-ph" style={{
                                        color: 'rgb(68, 68, 68)',
                                        fontSize: '35px',
                                        fontWeight: '400',
                                        fontFamily: 'Libre Baskerville'
                                    }}>Services</h2>
                                    <p className="text-ph" style={{
                                        fontSize: '17px',
                                        fontFamily: 'Libre Baskerville',
                                        fontWeight: '300',
                                        color: 'rgb(68, 68, 68)',
                                        lineHeight: '2',
                                        textAlign: 'justify',
                                        marginTop: "-10px"
                                    }}>Since our inception, Hyde has been dedicated to providing supreme services to improve our clients’ competitiveness and industry influences. We strive to reinforce the critical skills of our clients’ team members and to make sustainable and impactful changes in society.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row row-a">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-6-mr-top portfolio-item filter-app img-center" style={{ marginLeft: "-5%" }}>
                                <div className="cardd text-center" style={{ width: "18rem" }}>
                                    <div className="card-body">
                                        <img src={img13} className="cardimg" alt="..." />
                                        <h5 className="sub-h-ph bottom-space" style={{
                                            fontSize: '16px',
                                            fontFamily: 'Mulish',
                                            fontWeight: 'bold',
                                            color: "rgb(68, 68, 68)"
                                        }}>International Sales &
                                            <br />Marketing</h5>
                                        <Link to='international_marketing' className="text-ph p-text btn btn-get">See more <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-6-mr-top portfolio-item filter-app img-center">
                                <div className="cardd text-center" style={{ width: "18rem" }}>
                                    <div className="card-body">
                                        <img src={img16} className="cardimg" />
                                        <h5 className="sub-h-ph bottom-space-2" style={{
                                            fontSize: "16px",
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: 'bold',
                                            color: 'rgb(68, 68, 68)'
                                        }}>Human Resources</h5>
                                        <br />
                                        <Link to='human_resource' className="text-ph p-text btn btn-get b-2 btn-xtra">See more <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-6-mr-top portfolio-item filter-app img-center">
                                <div className="cardd text-center" style={{ width: "18rem" }}>
                                    <div className="card-body">
                                        <img src={img15} className="cardimg" />
                                        <h5 className="sub-h-ph bottom-space" style={{
                                            fontSize: '16px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: 'bold',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "19px"
                                        }}>Research &<br />Development</h5>
                                        <Link to='research-development' className="p-text text-ph btn btn-get b-3 btn-xtra">See more <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-6-mr-top portfolio-item filter-app img-center">
                                <div className="cardd text-center" style={{ width: "18rem" }}>
                                    <div className="card-body">
                                        <img src={img14} className="cardimg" />
                                        <h5 className="sub-h-ph bottom-space-2" style={{
                                            fontSize: '16px',
                                            fontFamily: 'Libre Baskerville',
                                            fontWeight: 'bold',
                                            color: 'rgb(68, 68, 68)',
                                            marginBottom: "19px"
                                        }}>Business Support <br />&nbsp;</h5>
                                        <Link to='business-support' className="text-ph p-text btn btn-get">See more <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>


                        </div>
                    </div>
                </section>
                {/* <!-- End Services Section --> */}

                {/* <!-- ======= Our Portfolio Section ======= --> */}
                <section id="portfolio" className="portfolio" style={{ paddingBottom: "75px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-9">
                                <div className="section-title section-title-phone text-left" style={{ marginLeft: "90px" }}>
                                    <h2 className="main-heading sub-h-ph heading-underline" style={{
                                        color: 'rgb(68, 68, 68)',
                                        fontSize: '35px',
                                        fontWeight: '400',
                                        fontFamily: 'Libre Baskerville'
                                    }}>Main Industry</h2>
                                    <p className="text-ph" style={{
                                        fontSize: '17px',
                                        fontFamily: 'Libre Baskerville',
                                        fontWeight: '300',
                                        color: 'rgb(68, 68, 68)',
                                        lineHeight: '2',
                                        marginTop: "-10px"
                                    }}>Our sector specialists support all types of clients.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-2 col-6  col-md-3 portfolio-item filter-app">
                                <img src={img1} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Urban Planning</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img2} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Medical Science</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img3} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Environmental Science</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img4} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Materials Science</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2"></div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img5} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Renewable Energy</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img6} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Marine Engineering</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img7} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Chemistry</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img8} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Engineering & Manufacturing</h4>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img9} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Information Technology</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img10} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Data Science & Social Data Science</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img11} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">Business & Management</h4>
                            </div>

                            <div className="col-lg-2 col-6 col-md-3 portfolio-item filter-app">
                                <img src={img12} className="img-fluid image-height" alt="" />
                                <h4 className="decor-text">AI & Robotics</h4>
                            </div>

                        </div>
                    </div>
                </section>
                {/* <!-- End Our Portfolio Section --> */}


            </main>
        </Fragment>
    )
}

export default Services
