import React, { Fragment } from 'react'
import '../styles/styles.css'
import '../styles/businessSupport.css'
import businessImg from '../img/business.gif'
import pic1 from '../img/pic-1.jpg'
import pic2 from '../img/pic-2.jpg'
import pic3 from '../img/pic-3.jpg'
import pic4 from '../img/pic-4.jpg'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

function businessSupport() {
    return (
        <Fragment>
            <Helmet>
                    <title>Hyde International (UK) | Business Support</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="Hyde International (UK) | Business Support" />
                    <meta name="description" content="Hyde lets you hand over the humdrum, overwhelming, and tricky tasks that work throws at you so you can enjoy running your business. Our sophisticated team will take care of errands and to-do lists for international businesses and individuals with too much on their plates." />
                    <meta name="keywords" content="business project, business support hyde,hyde international uk,hyde uk, hyde about us " />
                    <meta property="og:title" content="Hyde International (UK) | Business Support" />
                    <meta property="og:description" content="Hyde lets you hand over the humdrum, overwhelming, and tricky tasks that work throws at you so you can enjoy running your business. Our sophisticated team will take care of errands and to-do lists for international businesses and individuals with too much on their plates." />



                </Helmet>
            <main id="main">
                {/* <!-- ======= Inter Marketing Section ======= --> */}
                <section id="hero">
                    <div id="heroCarousel" className="carousel">
                        <div className="row div-box justify-content-center" style={{ height: "450px" }}>
                            <div className="col-md-7 col-lg-7">
                                {/* <!-- <div className="carousel-item active" style="background-image: url(assets/img/RND.gif);background-size: contain;height: calc(90vh - 162px);"></div> --> */}
                                <img className="img-fluid carosal-no-img carousel-item d-block m-auto tab-ipad" style={{
                                    width: 'auto',
                                    height: "100%"
                                }}
                                    src={businessImg} />
                            </div>
                            <div className="col-md-5 col-lg-5 col-md-12 text-center">
                                <h2 className="carosal-heading-ph" style={{
                                    fontSize: '55px',
                                    marginBottom: '-40px',
                                    marginTop: '9%',
                                    marginBottom: '1px',
                                    color: 'white',
                                    fontFamily: 'Libre Baskerville',
                                    fontSize: '45px',
                                    fontWeight: 'bolder'
                                }}
                                >Business Support</h2><br /><br />
                                <p className="carosal-text-ph" style={{
                                    textAlign: 'center',
                                    lineHeight: '2',
                                    paddingLeft: '15px',
                                    paddingRight: '30px',
                                    fontFamily: 'Libre Baskerville',
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'white',
                                    marginLeft: '50px',
                                    marginRight: '50px'
                                }}>Hyde lets you hand over the humdrum, overwhelming, and tricky tasks that work throws at you so you can enjoy running your business. Our sophisticated team will take care of errands and to-do lists for international businesses and individuals with too much on their plates.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Cards section --> */}

                <section className="section-margin">
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-lg-6" >
                                <div className="card-deck">
                                    <div className="card cards-ph mb-4" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6); width: 400px" }}>
                                        <div className="card-body" style={{ textAlign: "-webkit-center" }}>
                                            <h2 className="heading-underline sub-h-ph" style={{
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '25px',
                                                fontWeight: '500',
                                                color: "rgb(68, 68, 68)"
                                            }}>Event Solution</h2>
                                            <p className="card-text text-ph" style={{
                                                textAlign: 'justify',
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '17px',
                                                lineHeight: '1.5',
                                                fontWeight: '300',
                                                color: 'rgb(68, 68, 68)',
                                                padding: "30px"
                                            }}>Our international travel trade and corporate event management services include everything from finding the perfect venue to sourcing the right suppliers/participants. We help with everything related to delegate management and travel trade, from organising travel details of delegates to event planning.</p>
                                        </div>
                                        <img className="card-img-bottom" src={pic1} alt="Card image" style={{ width: '100%', padding: "5%" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card-deck">
                                    <div className="cards-ph card mb-4" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6); width: 400px" }}>
                                        <div className="card-body" style={{ textAlign: "-webkit-center" }}>
                                            <h2 className="heading-underline sub-h-ph" style={{
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '25px',
                                                fontWeight: '500',
                                                color: "rgb(68, 68, 68)"
                                            }}>Consulting</h2>
                                            <p className="card-text text-ph" style={{
                                                textAlign: 'justify',
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '17px',
                                                lineHeight: '1.5',
                                                fontWeight: '300',
                                                color: 'rgb(68, 68, 68)',
                                                padding: "30px"
                                            }}>Our specialist consultants stimulate your creativity and improve your productivity and efficiency with their wisdom in various industries. We develop and enhance your employees' abilities and cultural awareness within your own institutional context. We can help you adapt and optimise your communication, your management, and your negotiating ability to unlock your full potential in the new market.</p>
                                        </div>
                                        <img className="card-img-bottom" src={pic2} alt="Card image" style={{ width: '100%', padding: "5%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{ paddingBottom: "75px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card-deck">
                                    <div className="cards-ph card mb-4" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6); width: 400px" }}>
                                        <div className="card-body" style={{ textAlign: "-webkit-center" }}>
                                            <h2 className="heading-underline sub-h-ph" style={{
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '25px',
                                                fontWeight: '500',
                                                color: "rgb(68, 68, 68)"
                                            }}>Translation & Interpretation</h2>
                                            <p className="card-text text-ph" style={{
                                                textAlign: 'justify',
                                                fontFamily: 'Libre Baskerville',
                                                fontSize: '17px',
                                                lineHeight: '1.5',
                                                fontWeight: '300',
                                                color: 'rgb(68, 68, 68)',
                                                padding: "30px"
                                            }}>Mis-translation/mis-interpretation due to lack of industry knowledge can be disturbing. Our qualified Chinese–English translators and interpreters understand not only your languages, but also your business and culture.</p>
                                        </div>
                                        <img className="card-img-bottom" src={pic3} alt="Card image" style={{ width: '100%', padding: "5%" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card-deck">
                                    <div className="cards-ph card mb-4" style={{ boxShadow: "0 0 30px rgba(110, 110, 110, 0.6); width: 400px" }}>
                                        <div className="card-body" style={{ textAlign: "-webkit-center" }}>
                                            <h2 className="heading-underline sub-h-ph" style={{
                                                fontSize: '25px',
                                                fontWeight: '500',
                                                color: "rgb(68, 68, 68)"
                                            }}>Legal Support</h2>
                                            <p className="card-text card-font" style={{
                                                marginBottom: '-2rem !important',
                                                padding: '30px',
                                                fontFamily: 'Libre Baskerville'
                                            }}>Our proficient team will assist you with:
                                                <ul id="card-list">
                                                    <li>Overseas company registration in the UK/China,</li>
                                                    <li>Overseas trade mark/patent registration in the UK/China, and</li>
                                                    <li>Visa applications.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <img className="card-img-bottom" src={pic4} alt="Card image" style={{ width: '100%', padding: "5%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            {/* <!-- End #main --> */}
        </Fragment>
    )
}

export default businessSupport
