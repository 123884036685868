import React, { Fragment } from 'react'
import Hr1 from '../img/hr-1.png'
import Hr2 from '../img/hr-2.jpg'
import Hr3 from '../img/hr-3.png'
import '../styles/styles.css'
import '../styles/humanResouce.css'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

function humanResource() {
    return (
        <Fragment>
             <Helmet>
                    <title>Hyde International (UK) | Human Resource</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="Hyde International (UK) | Human Resource" />
                    <meta name="description" content="Develop Life-Changing Skills and Capabilities." />
                    <meta name="keywords" content="human resource, man-power, human resource hyde,hyde international uk,hyde uk" />
                    <meta property="og:title" content="Hyde International (UK) | Human Resource" />
                    <meta property="og:description" content="Develop Life-Changing Skills and Capabilities." />



                </Helmet>
            <main id="main">
                {/* <!-- ======= Inter Marketing Section ======= --> */}
                <section id="hero">
                    <div className="hero-container">
                        <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

                            <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                            <div className="carousel-inner" role="listbox" style={{ height: "450px" }}>

                                {/* <!-- Slide 1 --> */}
                                <div className="carousel-item active carousel-img-tb" style={{ backgroundImage: `url(${Hr1})`, backgroundPosition: "center" }}>
                                    <div className="carousel-container carousel-container-a" style={{
                                        background: 'rgba(0, 0, 0, 0.4)',
                                        color: '#fff',
                                        width: 'auto',
                                        height: '50%',
                                        marginTop: "79px"
                                    }}>
                                        <div className="carousel-content">
                                            <div style={{ textAlign: "center" }}>
                                                <h2 className="h2-tb" style={{
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '400',
                                                    fontSize: '45px',
                                                    color: 'white',
                                                    marginTop: "-5%"
                                                }}>Career Development</h2>
                                                <p className="text-ph" style={{
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '300',
                                                    fontSize: '16px',
                                                    color: "white"
                                                }}>Develop Life-Changing Skills and Capabilities</p>
                                                <a href="#icpd" className="btn-get-start text-ph btn-get scrollto" style={{
                                                    border: '2px white solid',
                                                    fontFamily: 'Mulish',
                                                    fontSize: '14px',
                                                    fontWeight: '300',
                                                    color: "white"
                                                }}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item" style={{ backgroundImage: `url(${Hr2})` }}>
                                    <div className="carousel-container carousel-container-tb" style={{
                                        background: 'rgba(0, 0, 0, 0.4)',
                                        color: '#fff',
                                        width: 'auto',
                                        height: '50%',
                                        marginTop: "79px"
                                    }}>
                                        <div className="carousel-content">
                                            <div style={{ textAlign: "center" }}>
                                                <h2 className="h2-tb" style={{
                                                    marginTop: '-5%',
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '400',
                                                    fontSize: '45px',
                                                    color: "white"
                                                }}>Global Recruitment</h2>
                                                <p className="text-ph" style={{
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '300',
                                                    fontSize: '16px',
                                                    color: "white"
                                                }}>Easily Find Future Colleagues On The Other Side Of The World</p>
                                                <a href="#global_re" className="btn-get-start text-ph btn-get scrollto" style={{
                                                    border: '2px white solid',
                                                    fontFamily: 'Mulish',
                                                    fontSize: '14px',
                                                    fontWeight: '300',
                                                    color: "white"
                                                }}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item" style={{ backgroundImage: `url(${Hr3})`, backgroundPosition: "center" }}>
                                    <div className="carousel-container carousel-container-tb" style={{
                                        background: 'rgba(0, 0, 0, 0.4)',
                                        color: '#fff',
                                        width: 'auto',
                                        height: '50%',
                                        marginTop: "79px"
                                    }}>
                                        <div className="carousel-content">
                                            <div style={{ textAlign: "center" }}>
                                                <h2 className="h2-tb" style={{
                                                    marginTop: '-5%',
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '400',
                                                    fontSize: '45px',
                                                    color: "white"
                                                }}>Cultural Awareness</h2>
                                                <p className="text-ph" style={{
                                                    fontFamily: 'Libre Baskerville',
                                                    fontWeight: '300',
                                                    fontSize: '16px',
                                                    color: "white"
                                                }}>Do global business as an insider</p>
                                                <a href="#global_re" className="btn-get-start text-ph btn-get scrollto" style={{
                                                    border: '2px white solid',
                                                    fontFamily: 'Mulish',
                                                    fontSize: '14px',
                                                    fontWeight: '300',
                                                    color: "white"
                                                }}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                                <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>

                        </div>
                    </div>
                </section>

                <section className="page-background section-mt-top" style={{ paddingBottom: "75px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-8 col-lg-8">
                                <div className="p-3 mb-5 bg-grey" style={{
                                    boxShadow: "0 0 30px rgba(110, 110, 110, 0.6)",
                                    backgroundColor: "white"
                                }}>
                                    <h2 id="icpd" className="heading-underline para-title-ph" style={{
                                        fontFamily: 'Libre Baskerville',
                                        fontSize: '25px',
                                        fontWeight: '400',
                                        color: "rgb(68, 68, 68)"
                                    }}>International CPD Training</h2>
                                    <div className="para-text-ph" style={{
                                        textAlign: 'justify',
                                        fontFamily: 'Libre Baskerville',
                                        fontSize: '17px',
                                        fontWeight: '300',
                                        lineHeight: '1.5',
                                        color: "rgb(68, 68, 68)"
                                    }}>
                                        <p>Our customised overseas CPD (Continuing Professional Development) training programmes between China and Europe align with the larger goals of government ministries, departments, organisations, and businesses while ensuring the specific expectations of the trainees.</p>
                                        <p>Our coaching pool, which spans around the world, is top quality. Our advanced coaching methods are based on evidence from studies conducted in the last 30 years. Trainees are thus benefited from a multicultural perspective in a variety of ways:</p>
                                        <ul style={{ marginLeft: '50px' }}>
                                            <li><p>Smelt and shine up key people in your organisation.</p></li>
                                            <li><p>Cultivate leadership potential, succeed in planning, and optimise organisational structure.</p></li>
                                            <li><p>Improve work efficiency, skills, knowledge, and competencies in a cost-effective way.</p></li>
                                            <li><p>Take advantage of door-to-door or on-site international business development opportunities with globally recognised institutions and individuals.</p> </li>
                                            <li><p>Solve ad hoc problems within your own industry-specific context.</p></li>
                                        </ul>
                                        <p>All of our overseas CPD training programmes will be customised for you to optimise the industry-specific balance between in-className training and on-site visits.</p>
                                    </div>
                                </div>

                                <div className="p-3 mb-5 bg-grey" style={{
                                    boxShadow: '0 0 30px rgba(110, 110, 110, 0.6)',
                                    backgroundColor: "white"
                                }}>
                                    <h2 id="global_re" className="heading-underline para-title-ph" style={{
                                        fontFamily: 'Libre Baskerville',
                                        fontSize: '25px',
                                        fontWeight: '400',
                                        color: "rgb(68, 68, 68)"
                                    }}>Global Talent Recruitment</h2>
                                    <div className="para-text-ph" style={{
                                        textAlign: 'justify',
                                        fontFamily: 'Libre Baskerville',
                                        fontSize: '17px',
                                        fontWeight: '300',
                                        lineHeight: '1.5',
                                        color: "rgb(68, 68, 68)"
                                    }}><p>We are cost effective in providing HR solutions between Europe and China. We help organisations to identify and to recruit ideal candidates through our talent pipeline across the world. </p>
                                        <p>Our outstanding HR professions ensuring the the best implementation to support your HR strategies.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                        </div>
                    </div>
                </section>


            </main>
            {/* <!-- End #main --> */}
        </Fragment>
    )
}

export default humanResource
