import { Fragment } from 'react';
import Home from './pages/home'
import AboutUs from './pages/aboutUs'
import Services from './pages/services';
import Opportunity from './pages/opportunity';
import ContactUs from './pages/contactUs';
import InterMarketing from './pages/interMarketing'
import Headers from './components/header';
import Footer from './components/footer';
import HumanResource from './pages/humanResource';
import PrivacyPolicy from './pages/privacyPolicy';
import CookiePolicy from './pages/cookiePolicy';
import TermsofService from './pages/termsofService';
import './styles/styles.css'
import {
  Switch,
  Route
} from "react-router-dom";
import BusinessSupport from './pages/businessSupport';
import RnD from './pages/RnD';
import AdminAddressEdit from './pages/admin';
import Admin from './pages/admin';
import Login from './pages/login';


function App() {
  return (
    <Fragment>
      <Headers />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/services' component={Services} />
        <Route path='/opportunity' component={Opportunity} />
        <Route path='/international_marketing' component={InterMarketing} />
        <Route path='/research-development' component={RnD}/>
         <Route path='/business-support' component={BusinessSupport}/> 
        <Route path='/human_resource' component={HumanResource} />
        <Route path='/policy-pages' component={PrivacyPolicy} />
        <Route path='/cookies-pages' component={CookiePolicy} />
        <Route path='/termsof_services-pages' component={TermsofService} />
        <Route path='/login' component={Login} />
        <Route path='/admin/address_edit' component={Admin} />
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;
