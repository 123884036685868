import React, { Fragment } from 'react'
import '../styles/interMarketing.css'
import '../styles/styles.css'
import Ss13 from '../img/slide/ss-13.jpg'
import Ss12 from '../img/slide/ss-12.png'
import Ss11 from '../img/slide/ss-11.jpg'
import Inter1 from '../img/inter-1.png'
import Inter2 from '../img/inter-2.png'
import Inter3 from '../img/inter-3.png'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'


function interMarketing() {
    return (
        <Fragment>
            <Helmet>
                    <title>Hyde International (UK) | International Maketing</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="Hyde International (UK) | International Maketing" />
                    <meta name="description" content="Reach Global Clients From Your Own Office" />
                    <meta name="keywords" content="interntanational marketing,tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk " />
                    <meta property="og:title" content="Hyde International (UK) | International Maketing" />
                    <meta property="og:description" content="Reach Global Clients From Your Own Office" />



                </Helmet>
            {/* <Headers /> */}
            <main id="main">
                {/* <!-- ======= Inter Marketing Section ======= --> */}
                <section id="hero" className="sec-height">
                    <div className="hero-container">
                        <div id="heroCarousel" className="carousel slide">

                            {/* <!-- <ol className="carousel-indicators" id="hero-carousel-indicators"></ol> --> */}

                            <div className="carousel-inner" role="listbox">

                                {/* <!-- Slide 2 --> */}
                                <div className="carousel-item active" style={{ backgroundImage: `url(${Ss13})`, height: "450px" }}>
                                    <div className="carousel-container" style={{
                                        background: 'rgba(255, 255, 255, 40%)',
                                        color: "#fff"
                                    }}>
                                        <div className="carousel-content container d-flex justify-content-center">
                                            <div className="centered">
                                                <h2 className="slider-headings-ph" style={{
                                                    fontSize: '45px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: 'white'
                                                }}>GLOBAL BUSINESS <span className="tex-color"><br />DIALOGUE</span></h2>
                                                <p className="text-ph" style={{
                                                    fontSize: '16px',
                                                    fontWeight: '300',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: "white"
                                                }}>Reach Global Clients From Your Own Office</p>
                                                <a href="#global" style={{
                                                    fontFamily: 'Mulish',
                                                    color: "white"
                                                }} className="btn-get-started text-ph btn-get scrollto">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Slide 1 --> */}
                                <div className="carousel-item" style={{ backgroundImage: `url(${Ss12})`, height: "450px" }}>
                                    <div className="carousel-container">
                                        <div className="carousel-content container d-flex justify-content-center">
                                            <div className="centered">
                                                <h2 className="slider-headings-ph" style={{
                                                    fontSize: '45px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: "white"
                                                }}>INTERNATIONAL<br />MARKET INSIGHTS</h2>
                                                <p className="text-ph" style={{
                                                    fontSize: '16px',
                                                    fontWeight: '300',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: "white"
                                                }}>Penetrate the Biggest (Emerging) Markets</p>
                                                <a href="#market" style={{
                                                    fontFamily: 'Mulish',
                                                    color: "white"
                                                }} className="btn-get-started text-ph btn-get scrollto">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* <!-- Slide 3 --> */}
                                <div className="carousel-item" style={{ backgroundImage: `url(${Ss11})`, height: "450px" }}>
                                    <div className="carousel-container" style={{ background: 'rgba(255, 255, 255, 40%)' }}>
                                        <div className="carousel-content container d-flex justify-content-center">
                                            <div className="centered">
                                                <h2 className="slider-headings-ph" style={{
                                                    fontSize: '45px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: "white"
                                                }}>NETWORKING</h2>
                                                <p className="text-ph" style={{
                                                    fontSize: '16px',
                                                    fontWeight: '300',
                                                    fontFamily: 'Libre Baskerville',
                                                    color: "white"
                                                }}>It Is Not So Much About Who You Know As About Who Knows You</p>
                                                <a href="#network" style={{
                                                    fontFamily: 'Mulish',
                                                    color: "white"
                                                }} className="btn-get-started text-ph btn-get scrollto">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                                <span className="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>

                        </div>
                    </div>
                </section>



                <section className="section-margin-ph">
                    <div className="container">
                        <div className="row row-mr-l" style={{ marginLeft: "-10%" }}>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                <img className="img-responsive icon-size center" src={Inter1}
                                    style={{ width: "40%", height: "30%", marginBottom: "5%" }} />
                                <h3 className="sub-h-ph" style={{
                                    textAlign: 'center',
                                    borderBottom: '2px solid',
                                    marginLeft: '15%',
                                    marginRight: '15%',
                                    marginTop: '5%',
                                    height: '55px',
                                    fontSize: '18px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    color: 'rgb(68, 68, 68)'
                                }}>International <br />B2B Sales</h3>
                                <div className="text-left text-ph" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    fontFamily: 'Mulish',
                                    color: 'rgb(149, 134, 119)',
                                    lineHeight: "1"
                                }}>
                                    <p style={{ textAlign: "center" }}>B2B Sales & Pitching</p>
                                    <p style={{ textAlign: "center" }}>Travel Trade</p>
                                    <p style={{ textAlign: "center" }}>Procurement & Product Sourcings</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                <img className="img-responsive icon-size center" src={Inter2}
                                    style={{ width: '35%', height: '30%', marginBottom: "5%" }} />
                                <h3 className="sub-h-ph" style={{
                                    textAlign: 'center',
                                    borderBottom: '2px solid',
                                    marginLeft: '15%',
                                    marginRight: '15%',
                                    marginTop: '5%',
                                    height: '55px',
                                    fontSize: '18px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    color: "rgb(68, 68, 68)"
                                }}>International <br />Marketing</h3>
                                <div className="text-left text-ph" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    fontFamily: 'Mulish',
                                    color: 'rgb(149, 134, 119)',
                                    lineHeight: "1"
                                }}>
                                    <p style={{ textAlign: "center" }}>Localisation</p>
                                    <p style={{ textAlign: "center" }}>International Promotion</p>
                                    <p style={{ textAlign: "center" }}>Digital Marketing</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                <img className="img-responsive icon-size center" src={Inter3}
                                    style={{ width: '35%', height: '30%', marginBottom: "5%" }} />
                                <h3 className="sub-h-ph" style={{
                                    textAlign: 'center',
                                    borderBottom: '2px solid',
                                    marginLeft: '15%',
                                    marginRight: '15%',
                                    marginTop: '5%',
                                    height: '55px',
                                    fontSize: '18px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    color: "rgb(68, 68, 68)"
                                }}>Development & Cooperation</h3>
                                <div className="text-left text-ph" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    fontFamily: 'Mulish',
                                    color: 'rgb(149, 134, 119)',
                                    lineHeight: "1"
                                }}>
                                    <p style={{ textAlign: "center" }}>Business Networking</p>
                                    <p style={{ textAlign: "center" }}>Business Development</p>
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </section>

                <section style={{ padding: "30px 0" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-xs-2 col-lg-2"></div>
                            <div className="col-md-8 col-xs-8 col-lg-8">
                                <h3 className="para-title-ph" id="global" style={{
                                    borderBottom: '5px solid',
                                    width: 'max-content!important',
                                    width: '30%',
                                    borderWidth: 'thick',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    fontSize: '25px',
                                    color: 'rgb(68, 68, 68)',
                                    marginBottom: "2%"
                                }}>Global Business Dialogue</h3>
                                <p className="para-text-ph" style={{
                                    textAlign: 'justify',
                                    lineHeight: '1.5',
                                    fontSize: '17px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)'
                                }}>
                                    Whether you are an SME, an industrial giant, or a government arm, approaching your targeted clients or
                                    business partners on the other side of the world requires sophisticated technology and significant labour.
                                    Hyde's extensive global connections particularly in China and Europe will bring well-selected trade leads
                                    to your doorstep. All you need to do is pitch your business ideas and output to your potential
                                    clients/partners from the comfort of your own office. Leave the hustle and bustle to us!
                                </p><br /><br />

                                <h3 className="para-title-ph" id="market" style={{
                                    borderBottom: '5px solid',
                                    width: 'max-content!important',
                                    borderWidth: 'thick',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    fontSize: '25px',
                                    color: 'rgb(68, 68, 68)',
                                    marginBottom: "2%"
                                }}>International Market Insights</h3>
                                <p className="para-text-ph" style={{
                                    textAlign: 'justify',
                                    lineHeight: '1.5',
                                    fontSize: '17px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)'
                                }}>
                                    As one of the fastest growing (emerging) markets in the world, the Chinese market is attractive to
                                    organisations of all sizes. Whether you are an independent inventor, an SME, an industrial giant, or a
                                    government arm, we will accelerate and accurately shape your path towards the Chinese market while
                                    unlocking the full potential of your growth/development/business operations in both China and the UK. We
                                    can also help existing and experienced participants in the market to maximise their risk-adjusted rewards
                                    in the rapidly evolving market and adapt to the unique political and economic environments that present
                                    both significant opportunities and challenges.
                                    <br /><br />
                                    Our dedicated marketing communications events, theme-based seminars, high-end industrial conferences and
                                    be spoke travel trade programmes will enable you to gain comprehensive and in-depth market insights
                                    theoretically and practically.
                                </p><br />

                                <h3 className="para-title-ph" id="network" style={{
                                    borderBottom: '5px solid',
                                    width: 'max-content!important',
                                    borderWidth: 'thick',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '400',
                                    fontSize: '25px',
                                    color: 'rgb(68, 68, 68)',
                                    marginBottom: "2%"
                                }}>Networking</h3>
                                <p className="para-text-ph" style={{
                                    textAlign: 'justify',
                                    lineHeight: '1.5',
                                    fontSize: '17px',
                                    fontFamily: 'Libre Baskerville',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    marginBottom: '75px'
                                }}>
                                    We do not do invalid or inefficient networking; we promote our clients effectively. For over 20 years,
                                    Hyde has created a thriving commercial eco-community to bridge Europe and China, providing network,
                                    support, and advice for businesses of all kinds. It has also established a friendly platform for
                                    entrepreneurs, politicians, investors, researchers, and other think-alike peers to make solid and
                                    sustainable connections in a welcoming atmosphere. We aim to enable equal and friendly networking for
                                    everyone from different backgrounds and cultures. We believe that opportunities do not float like clouds
                                    in the sky but are in fact attached to people.
                                </p>
                            </div>
                            <div className="col-md-2 col-xs-2 col-lg-2"></div>
                        </div>
                    </div>
                </section>


            </main>
            {/* <!-- End #main --></div> */}
            {/* <Footer /> */}
        </Fragment>
    )
}

export default interMarketing
