import React, { Fragment } from 'react'
import '../styles/styles.css'
import {Link} from 'react-router-dom'

function Footer() {
    return (
        <Fragment>
            <footer id="footer">
                <div className="container" style={{
                    marginTop: '-15px',
                    marginBottom: '-15px'
                }}>
                    <div className="copyright">
                        <Link to='/policy-pages' style={{ color: 'white', fontFamily: 'Libre Baskerville' }}><u>Privacy & Terms of Use</u></Link><br />
                        <p className="footer-ph">&copy; Copyright Hyde International (UK). All Rights Reserved</p>
                    </div>
                    <div className="credits">
                        {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/mamba-one-page-bootstrap-template-free/ --> */}
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer
