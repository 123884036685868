import React, { useState } from 'react'
import '../styles/admin.css'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'

function Login() {

    var history = useHistory()

    const [userName, setuserName] = useState('')
    const [passWord, setpassWord] = useState('')

    const loginHandler = () => {
        Axios.post('http://localhost:8000/hyde_international/login', { username: userName, password: passWord }).then((response) => {
            if (response) {
                alert('Welcome to admin portal!')
                history.push('/admin/address_edit')
            }
        }).catch((err) => {
            alert('Username and password does not match.')
        })
    }
    return (
        <div className='container'>
            <div className='loginTable'>
                <label htmlFor="userName">Username:</label>
                <input type="text" className='form-control' required onChange={(e) => setuserName(e.target.value)} />
                <br />
                <label htmlFor="passWrod">Password</label>
                <input type="password" className='form-control' required onChange={(e) => setpassWord(e.target.value)} />

                <button className='btn-apply' onClick={loginHandler}>Login</button>
            </div>
        </div>
    )
}

export default Login
