import React, { Fragment } from 'react'
import '../styles/styles.css'
import '../styles/RnD.css'
import rndGIF from '../img/RND.gif'
import rndImg from '../img/rnd-1.png'
import rndImg2 from '../img/rnd-2.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'


function RnD() {
    return (
        <Fragment>
            <Helmet>
                <title>Hyde International (UK) | Research and Development</title>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="title" content="Hyde International (UK) | Research and Development" />
                <meta name="description" content="Hyde forge links between cutting-edge research and industrial solutions to find new homes for our clients' trail-blazing innovations, inspiring novel business opportunities, and products and services." />
                <meta name="keywords" content="talents opportunity, r&d talents, r&d, tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk " />
                <meta property="og:title" content="Hyde International (UK) | Research and Development" />
                <meta property="og:description" content="Hyde forge links between cutting-edge research and industrial solutions to find new homes for our clients' trail-blazing innovations, inspiring novel business opportunities, and products and services." />



            </Helmet>
            <section id="hero">
                <div id="heroCarousel" className="carousel">
                    <div className="row div-box justify-content-center" style={{ height: "450px" }}>
                        <div className="col-md-5-mr col-md-5 col-lg-5" style={{
                            textAlign: 'center',
                            fontFamily: 'Libre Baskerville'
                        }}>
                            <h2 style={{
                                fontSize: '55px',
                                marginBottom: '-40px',
                                marginTop: '12%',
                                marginLeft: '25%',
                                color: 'white',
                                fontSize: '45px',
                                fontWeight: 'bolder',
                                textAlign: "left"
                            }} className="text-marg-ph sub-ph">Research & <br id="brk" />Development</h2>
                            <ul className="text-ph list-style">
                                <li><a href="#techtranspro" className="scrollto"><u>Technology Transfer Programme</u></a></li>
                                <li><a href="#IPM" className="scrollto"><u>International Partner Matching</u></a></li>
                                <li><a href="#IPM" className="scrollto"><u>Global Technology Scouting</u></a></li>
                            </ul>
                        </div>
                        <div className="col-md-7 col-lg-7">
                            {/* <!-- <div className="carousel-item active" style="background-image: url(assets/img/RND.gif);background-size: contain;height: calc(90vh - 162px);"></div> --> */}
                            <img className="img-fluid carousel-item d-block m-auto gif-right hide-gif-ph" style={{
                                width: "100%",
                                height: '100%',
                                right: '-100px',
                                opacity: "0.7"
                            }}
                                src={rndGIF} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-mt-top tp-btm" id="techtranspro" style={{ paddingBottom: "60px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 text-center">
                            <div className="mr-l-tb">
                                <h2 style={{
                                    marginLeft: '15%',
                                    fontFamily: 'Libre Baskerville',
                                    fontSize: '32px',
                                    fontWeight: '400',
                                    color: "rgb(68, 68, 68)"
                                }} className="heading-underline heading-adj para-title-ph para-title-tb">Technology Transfer Programme</h2>
                                <p className="p-text para-text-ph text-mr-bt" style={{
                                    marginLeft: '8%',
                                    marginTop: '0px',
                                    textAlign: 'justify',
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville',
                                    lineHeight: "1.5"
                                }}>
                                    We forge links between cutting-edge research and industrial solutions to find new homes for our clients' trail-blazing innovations, inspiring novel business opportunities, and products and services.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

            <section className="sinewave" style={{ paddingBottom: "60px" }}>
                <div className="container">
                    <div className="row text-center" style={{
                        marginRight: "0px !important",
                        marginLeft: "-5px !important"
                    }}>
                        {/* <!--   <img src="assets/img/sinewave.png" className="img-fluid" style="margin-bottom: -204px;
    margin-left: -22px;"> --> */}
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2">
                            <img style={{ marginTop: "-20%" }} className="img-responsive icon-size" src={rndImg} /><br /><br />
                            <div className="section-title-c mr-tp-ph-a">
                                <h3 className="sub-h-ph" style={{ color: 'rgb(149, 134, 119)', fontSize: '20px', fontFamily: 'Tenor Sans', fontWeight: "400" }}>FOCUS</h3>
                                <p className="text-ph text-mr-bt" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville',
                                    lineHeight: "1.5"
                                }}>Our fruitful community and network will allow our clients to spend more time building and less time searching.</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="section-title-c">
                                {/* <!-- <img className="img-responsive icon-size" src="assets/img/zero.png"><br><br> --> */}
                                <h3 className="sub-h-ph" style={{ color: 'rgb(149, 134, 119)', fontSize: '20px', fontFamily: 'Tenor Sans', fontWeight: "400" }}>ZERO-FRICTION<br />FUNCTIONING</h3>
                                <p className="text-ph text-mr-bt" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville',
                                    lineHeight: "1.5"
                                }}>90% of our projects are overseas high-tech projects. It doesn't matter whether you are an independent inventor wishing to start your own business or an SME company looking for external support to expand a project.</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="section-title-c mr-tp-ph-b">
                                <img className="img-responsive icon-size icon-xtra" src={rndImg2} /><br /><br />
                                <h3 className="sub-h-ph" style={{ color: 'rgb(149, 134, 119)', fontSize: '20px', fontFamily: 'Tenor Sans', fontWeight: "400" }}>TRANSPARENCY​</h3>
                                <p className="text-ph text-mr-bt" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville',
                                    lineHeight: "1.5"
                                }}>You will participate in the 100% financing process with full autonomy. Your intellectual property (IP) will be well protected.</p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="section-title-c">
                                {/* <!-- <img className="img-responsive icon-size" src="assets/img/support.png"><br><br> --> */}
                                <h3 className="sub-h-ph" style={{ color: 'rgb(149, 134, 119)', fontSize: '20px', fontFamily: 'Tenor Sans', fontWeight: "400" }}>SUPERIOR SUPPORT</h3>
                                <p className="text-ph" style={{
                                    fontSize: '17px',
                                    fontWeight: '300',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville',
                                    lineHeight: "1.5"
                                }}>Our professional support will enable you to create a culture of innovation. We will equip your employees and ideas with the tools, resources, and collaborative environment necessary to drive innovation.</p>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>

            <section id="IPM" style={{ padding: '30px 0', paddingBottom: '75px' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-6 text-center">
                            <div className="section-title-d">
                                {/* <!-- <h2 className="main-heading">International Partner Matching</h2> --> */}
                                <h2 style={{
                                    marginLeft: '8%',
                                    margin: 'auto',
                                    fontSize: '32px',
                                    fontWeight: '400',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville'
                                }} className="para-f-size heading-underline para-title-ph tab-underline">International Partner Matching</h2>
                            </div><br />
                            <p style={{
                                marginTop: '-20px',
                                fontSize: '17px',
                                fontWeight: '300',
                                color: 'rgb(68, 68, 68)',
                                fontFamily: 'Libre Baskerville',
                                lineHeight: "1.5"
                            }} className="para-text-ph para-mr">
                                Develop research or commercial partnerships that propel your ideas, projects, or business; remove barriers; and enable you to grow internationally and collectively.
                            </p><br />
                            <Link to='/contact-us' className="btn btn-mr btn-mr-tp" style={{
                                borderRadius: '20px',
                                background: 'rgb(149, 134, 119)',
                                color: 'white',
                                marginTop: "18px"
                            }} role="button">Contact Us</Link>
                        </div>
                        <div className="col-md-6 col-lg-6 text-center">
                            <div className="section-title-d">
                                <h2 style={{
                                    marginLeft: '8%',
                                    margin: 'auto',
                                    fontSize: '32px',
                                    fontWeight: '400',
                                    color: 'rgb(68, 68, 68)',
                                    fontFamily: 'Libre Baskerville'
                                }} className="para-f-size heading-underline para-title-ph tab-underline">Global Technology Scouting</h2>
                                {/* <!-- <h2 className="main-heading">Global Technology Scouting</h2> --> */}
                            </div><br />
                            <p style={{
                                marginTop: '-20px',
                                fontSize: '17px',
                                fontWeight: '300',
                                color: 'rgb(68, 68, 68)',
                                fontFamily: 'Libre Baskerville',
                                lineHeight: "1.5"
                            }} className="para-text-ph para-mr">
                                Our global start-up network and world-className research Knowledge Bank help corporations and investors systematically identify, vet, and manage relationships with top start-ups. We offer on-demand and annual scouting programmes to find start-ups with the greatest potential.
                            </p><br />
                            <Link to='/contact-us' className="btn btn-mr" style={{
                                background: 'rgb(149, 134, 119)',
                                borderRadius: '20px',
                                marginTop: '-49px',
                                color: "white"
                            }} role="button">
                                Become an Invester</Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- End Hero --> */}

            <main id="main">

            </main>
            {/* <!-- End #main --> */}
        </Fragment>
    )
}

export default RnD
