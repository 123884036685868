import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import '../styles/styles.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function header() {
    return (
        <Fragment>
            {/* <!-- ======= Header ======= --> */}
            <header className="header" style={{
                paddingBottom: '3rem',
                paddingTop: '20px'
            }}>
                <div className="container">
                    <div className="logo logo-phone float-left" style={{ marginLeft: '-55px' }}>
                        <Link to='/' style={{ textDecoration: "none", textAlign: "center", color: "#18407c", fontSize: "25px" }}>
                            <p style={{
                                fontSize: '20px',
                                fontWeight: '400',
                                color: 'rgb(68,68,68)',
                                fontFamily: 'Libre Baskerville',
                                borderBottom: '2px solid',
                                height: '27px',
                                width: "fit-content"

                            }}>
                                Hyde International (UK)
                            </p>
                            <p style={{
                                marginTop: '-17px',
                                fontSize: '11px',
                                fontWeight: '400',
                                color: 'rgb(149,134,119)',
                                fontFamily: 'Mulish',
                                textAlign: 'center'
                            }}>
                                CONNECT INTELLIGENT MINDS
                            </p>
                        </Link>
                        {/* <!-- Uncomment below if you prefer to use an image logo -->
          <!-- <a href="home.html"><img src="../static/assets/img/logo.png" alt="logo" className="img-fluid"></a> --> */}
                    </div>

                    <nav className="nav-menu navbar-phone float-right d-none d-lg-block" style={{ marginRight: '-85px' }}>
                        <ul id="dd" style={{
                            fontWeight: '400',
                            fontFamily: 'Libre Baskerville',
                            fontSize: '16px',
                            color: 'rgb(68,68,68)'
                        }}>
                            <li><Link to='/' className="btn-get">Home</Link></li>
                            <li className="dropdown"><Link to='/about-us' data-hover="dropdown" className="aboout-ph btn-get" style={{ textAlign: 'center' }}>About <ExpandMoreIcon /></Link>
                                <ul className="ul-ph" style={{ textAlign: 'left', fontSize: '16px' }}>
                                    <li><a href='/about-us#about' className="btn-get">About Us</a></li>
                                    <li><a href='about-us#whyus' className="btn-get">Why Us</a></li>
                                </ul>
                            </li>
                            <li className="dropdown service-margin"><Link to='/services' className="aboout-ph btn-get" style={{ textAlign: 'center' }}>Services <ExpandMoreIcon /></Link>
                                <ul className="ul-ph" style={{ textAlign: 'left', fontSize: '16px' }}>
                                    <li><Link to='/international_marketing' className="btn-get">International Sales & Marketing</Link></li>
                                    <li><Link to='/human_resource' className="btn-get">Human Resource</Link></li>
                                    <li><Link to='/research-development' className="btn-get">Research & Development</Link></li>
                                    <li><Link to='/business-support' className="btn-get">Business Support</Link></li>
                                </ul>
                            </li>
                            <li><Link to='/opportunity' className="btn-get">Opportunity</Link></li>
                            <li><Link to='/contact-us' h className="btn-get">Contact</Link></li>
                        </ul>
                    </nav>
                    {/* <!-- .nav-menu --> */}
                </div>
            </header>
            {/* <!-- End Header --> */}
        </Fragment>
    )
}

export default header
