import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'
import '../styles/header.css'
import '../styles/home.css'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

function Home() {
    return (
        <Fragment>
             <Helmet>
                    <title>Hyde International (UK)</title>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="title" content="HYDE INTERNATIONAL (UK)" />
                    <meta name="description" content="Leading bespoke cross-cultural solution and opportunity provider for organisations and talents who are dedicated to creating changes that matter." />
                    <meta name="keywords" content="research and developement grant,tech funding,tech incubator,university cryptocurrency projects,research gateway,hyde international uk,hyde uk " />
                    <meta property="og:title" content="HYDE INTERNATIONAL (UK)" />
                    <meta property="og:description" content="Leading bespoke cross-cultural solution and opportunity provider for organisations and talents who are dedicated to creating changes that matter." />



                </Helmet>
            {/* <!-- ======= Header ======= --> */}
            <header className="masthead" style={{
                paddingBottom: '22px',
                paddingTop: '20px'
            }}>
                
                <div className="container d-flex align-items-center">
                    <div className="">
                        <h2 className="title-ph text-style">Connect Business Between<br />
                            <span style={{ color: '#18407c' }}>Europe & China</span></h2>
                        <p className="text-ph p-text" style={{ marginLeft: '15%', color: 'rgb(22, 89, 148)', fontWeight: '400', fontSize: '20px', fontFamily: 'Mulish' }}>  Boutique Cross-Cultural Consultancy Firm</p>
                        <div className="btn-ph web-screen col-sm-6 col-sm-6 col-md-12 col-lg-12">
                            <Link to='services' className="btn btn-get-started b-ph" style={{ margiTop: '0%' }}>OUR SERVICES</Link>
                            <Link to='opportunity' style={{ marginRight: '-30%', marginTop: '0%' }} className="btn btn-get-started btn-margin">OPPORTUNITY</Link>
                        </div>
                    </div>
                </div>
            </header>

            <section style={{ padding: '7px 0' }}>

            </section >
        </Fragment>
    )
}

export default Home
